import React from "react";
import {
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  NavigationV2,
  SocialMedia,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  RecaptchaProvider,
  Background,
  Location,
  ExternalLinkMap,
  Phone,
  Email,
  FishermanBanner,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "semantic-ui-less/semantic.less";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          type
          logo
          socialMedia {
            link
            type
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 60)
            }
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://checkerspizza.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://checkerspizza.com"}
            businessType={fishermanBusiness.type}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <NavigationV2
                sticky={true}
                fullScreen={true}
                desktop={
                  <NavigationV2.Container>
                    <NavigationV2.Menu className={"internal-links"}>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          links={filterRegularLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                      <NavigationV2.Item className={"social-media-item"}>
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"rect"}
                          centerHeader={false}
                          colors={"secondary"}
                          groupButtons={false}
                          groupVertically={false}
                          inverted={true}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"logo"}>
                      <NavigationV2.Logo
                        title={
                          <Header as={"h3"}>{fishermanBusiness.name}</Header>
                        }
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"external-links"}>
                      <NavigationV2.Item className={"hours"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <HoursOfOperation
                              displayOption={"dailyWithPopup"}
                              displayOpenClosedStatus={true}
                            />
                          }
                          hookToPropsMap={{
                            hours: "activeLocation.hours",
                            timezone: "activeLocation.timezone",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </NavigationV2.Item>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          dropdownDirection={"left"}
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                  </NavigationV2.Container>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"mobile-header"}>
                      <NavigationV2.Toggle />
                      <NavigationV2.Menu className={"logo"}>
                        <NavigationV2.Logo
                          title={
                            <Header as={"h3"}>{fishermanBusiness.name}</Header>
                          }
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                    <NavigationV2.Container tray={true} vertical={true}>
                      <NavigationV2.Menu className={"internal-links"}>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                      <NavigationV2.Menu className={"external-links"}>
                        <NavigationV2.Item className={"hours"}>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <HoursOfOperation
                                displayOption={"dailyWithPopup"}
                                displayOpenClosedStatus={true}
                              />
                            }
                            hookToPropsMap={{
                              hours: "activeLocation.hours",
                              timezone: "activeLocation.timezone",
                            }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </NavigationV2.Item>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterButtonLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
            </React.Fragment>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Background columns={1} className={"texture-background"}>
                  <Background.Pattern type={"CIRCLE"}>
                    <Grid stackable={true}>
                      <Grid.Row>
                        <Grid stackable={true}>
                          <Grid.Row columns={3}>
                            <Grid.Column className={"address-column"}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <Location businessName={"Address"} />
                                }
                                hookToPropsMap={{
                                  "address.street": "activeLocation.street",
                                  "address.city": "activeLocation.city",
                                  "address.state": "activeLocation.state",
                                  "address.zip": "activeLocation.zipCode",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={<ExternalLinkMap />}
                                hookToPropsMap={{
                                  "address.street": "activeLocation.street",
                                  "address.city": "activeLocation.city",
                                  "address.state": "activeLocation.state",
                                  "address.zip": "activeLocation.zipCode",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </Grid.Column>
                            <Grid.Column className={"contact-us-column"}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <div>
                                    <strong>Contact us</strong>
                                  </div>
                                }
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                  email: "activeLocation.email",
                                }}
                                requiredProps={[["phone", "email"]]}
                                hookParams={[LocationsContext]}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Phone}
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Email}
                                hookToPropsMap={{
                                  email: "activeLocation.email",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <SocialMedia
                                socialMediaValues={
                                  fishermanBusiness.socialMedia
                                }
                                buttonType={"rect"}
                                centerHeader={false}
                                colors={"secondary"}
                                groupButtons={false}
                                groupVertically={false}
                                inverted={true}
                                labelPosition={"free"}
                                showLabels={false}
                              />
                            </Grid.Column>
                            <Grid.Column
                              className={"hours-of-operation-column"}
                            >
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <HoursOfOperation
                                    displayOption={"grouped"}
                                    header={<strong>Hours of Operation</strong>}
                                  />
                                }
                                hookToPropsMap={{
                                  hours: "activeLocation.hours",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Row>
                    </Grid>
                  </Background.Pattern>
                </Background>
              </footer>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
